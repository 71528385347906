@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  min-width: 375;
}

body {
  margin: 0;
  font-family: 'Archivo Black', serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'NT Brick Sans';
  src: url('NTBrickSans.ttf');
}

#logo_canvas {
  top:0;
}

.about_content {
  padding-top: 150px;
  padding-bottom: 52px;
}

@media (max-width: 1150px) {

  #logo_canvas {
    top: -210px;;
  }

  .about_content {
    padding-top: 380px;
  }
}

